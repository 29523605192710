<!--ReleaseDialogHeader-->
<template>
  <header class="flex h-84 shrink-0 gap-20 bg-background-variant-2 p-20">
    <div class="flex items-center">
      <button v-if="isShowBackBtn" type="button" class="shrink-0" @click="backToReleasePlan">
        <s-icon
          size="4xl"
          icon="ic-v2-control-arrow-left-line"
          class="align-top text-on-surface-elevation-1"
          srOnlyText="뒤로가기"
        />
      </button>
      <button v-else type="button" class="shrink-0" @click="backToReleasePlan">
        <s-icon
          size="4xl"
          icon="ic-v2-control-close-line"
          class="align-top text-on-surface-elevation-1"
          srOnlyText="닫기"
        />
      </button>

      <p class="ml-12 text-2xl font-bold leading-xl text-on-surface-elevation-1">
        {{ releaseDialogTitle }}
      </p>
    </div>

    <div class="ml-auto flex items-center gap-8">
      <slot></slot>
    </div>
  </header>
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router';

import { PRODUCT_HOME_PAGE_URL, PRODUCT_RELEASE_PLAN_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { redirectTo } from '@/utils/common.util';

const fromCalendar: boolean = (useRoute().query?.fromCalendar as string) === Confirmation.YES;

const props = withDefaults(
  defineProps<{
    isShowBackBtn?: boolean;
    releaseDialogTitle?: string;
    isDetail?: boolean;
  }>(),
  {
    isShowBackBtn: false,
    releaseDialogTitle: '',
    isDetail: false
  }
);

const backToReleasePlan = async () => {
  if (props.isDetail) {
    if (fromCalendar) {
      await redirectTo(PRODUCT_RELEASE_PLAN_PAGE_URL);
      return;
    }
  }
  await redirectTo(PRODUCT_HOME_PAGE_URL);
};
</script>
