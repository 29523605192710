<!--release-completion-dialog-->
<template>
  <!-- 출시 완료 팝업 -->
  <s-dialog to="release-completion-dialog" size="md" open>
    <s-dialog-overlay />
    <s-dialog-panel>
      <s-dialog-content>
        <s-dialog-content-body>
          <div class="py-[2.2rem] space-y-16">
            <img
              :src="
                srcImage ||
                  'https://d2x8kymwjom7h7.cloudfront.net/live/application_no/119001/images/일립스 온니.jpg'
              "
              alt="Product Image"
              class="block w-72 h-72 mx-auto rounded-3xl object-cover"
            />
            <p class="text-xl font-bold leading-lg text-on-surface-elevation-1 text-center">
              {{
                $t(
                  'studio.prj_prod.this_prod.release_stt_calendarview_manual_release_apply_pop_msg1'
                )
              }}<br />
              {{
                $t(
                  'studio.prj_prod.this_prod.release_stt_calendarview_manual_release_apply_pop_msg2'
                )
              }}<br />
              {{
                $t(
                  'studio.prj_prod.this_prod.release_stt_calendarview_manual_release_apply_pop_msg3'
                )
              }}
            </p>
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button variant="outline" @click="onClose">
          {{ $t('studio.common.popup_case_close_btn') }}
        </s-button>
        <s-button @click="openProductPage">
          {{
            $t(
              'studio.prj_prod.this_prod.release_stt_calendarview_manual_release_apply_pop_redirect_store_btn'
            )
          }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="release-completion-dialog" />
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { getConfigs, redirectTo } from '@/utils/common.util';

const props = defineProps<{
  srcImage: string;
  productNo: number;
}>();

const emit = defineEmits<{
  close: [inputName: string];
}>();

const onClose = () => {
  emit('close', '');
};

const { locale } = useI18n();

const storeHref = computed(() => {
  const { STORE_URL } = getConfigs();
  return `${STORE_URL}/${locale.value}`;
});

const openProductPage = async () => {
  const url = `${storeHref.value}/games/${props.productNo}`;
  await redirectTo(url, { external: true, open: { target: '_blank' } });
  onClose();
};
</script>
