import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { fetchReleasePlanInformationApi } from '@/apis/release-plan.api';
import { STATUS_CODE } from '@/constants/error.const';
import {
  PLAN_STATUS,
  PRODUCT_TYPE_DETAIL,
  SALE_STATUS,
  VERIFY_STATUS
} from '@/constants/products.const';
import {
  PRODUCT_LAUNCH_TYPE,
  RELEASE_PLAN_CHECK_LIST_STATUS,
  RELEASE_PLAN_LIMITED_SALES_STATUS,
  RELEASE_PLAN_PRODUCT_STATUS
} from '@/constants/release-plan.const';
import { Confirmation } from '@/enums/common.enum';
import useProductStore from '@/stores/product.store';
import type {
  CheckListStatus,
  ProductReleasePlanCheckList,
  ReleasePlanInformation
} from '@/types/release-plan/release-plan.type';
import type { SaveReleaseInformationRequest } from '@/types/release-plan/release-plan-request.type';
import { formatUnknownSrcToMillis } from '@/utils/date.util';

export const useReleasePlanStore = defineStore('releasePlanStore', () => {
  const productStore = useProductStore();
  const { currentProductDetailType } = storeToRefs(productStore);

  /**
   * Other status
   */

  // Form data
  const storeReleasePlanDetail = ref<SaveReleaseInformationRequest>();
  const limitedSaleExpired = computed(
    () => storeReleasePlanDetail.value?.plans.release?.sale?.saleLimitEndedAt
  );
  const isReleasePlanSaleStatusNone = computed(
    () =>
      storeReleasePlanDetail.value?.plans.release?.sale?.saleLimitType ===
      RELEASE_PLAN_LIMITED_SALES_STATUS.NONE
  );
  const isEndDateOverDateNow = computed(() => {
    if (!limitedSaleExpired.value) {
      return !isReleasePlanSaleStatusNone.value;
    }
    return releasedAt.value
      ? formatUnknownSrcToMillis(releasedAt.value) < Date.now()
      : formatUnknownSrcToMillis(limitedSaleExpired.value) < Date.now();
  });
  // API data
  const storeReleasePlanInformation = ref<ReleasePlanInformation>();
  const releasedAt = computed(() => storeReleasePlanInformation.value?.plans?.release?.releasedAt);
  const prePurchaseStartedAt = computed(
    () => storeReleasePlanInformation.value?.plans?.prePurchase?.prePurchaseSchedule?.startedAt
  );
  const prePurchaseEndedAt = computed(
    () => storeReleasePlanInformation.value?.plans?.prePurchase?.prePurchaseSchedule?.endedAt
  );
  const earlyAccessStartedAt = computed(
    () => storeReleasePlanInformation.value?.plans?.page?.earlyAccessSchedule?.startedAt
  );
  const earlyAccessEndedAt = computed(
    () => storeReleasePlanInformation.value?.plans?.page?.earlyAccessSchedule?.endedAt
  );

  const isOnlyPageOpenReleased = computed(() => {
    return (
      storeReleasePlanInformation.value?.planStatus === PLAN_STATUS.PAGE_OPEN &&
      storeReleasePlanInformation.value?.verifyStatus === VERIFY_STATUS.PASS &&
      storeReleasePlanInformation.value?.releaseType === PRODUCT_LAUNCH_TYPE.PAGE
    );
  });
  const isOfficialReleased = computed(() => {
    return (
      storeReleasePlanInformation.value?.planStatus === PLAN_STATUS.RELEASE &&
      storeReleasePlanInformation.value?.verifyStatus === VERIFY_STATUS.PASS &&
      storeReleasePlanInformation.value?.releaseType === PRODUCT_LAUNCH_TYPE.PAGE_BUILD
    );
  });
  const isOnlyPageOpenToOfficialReleased = computed(() => {
    return (
      storeReleasePlanInformation.value?.planStatus === PLAN_STATUS.PAGE_OPEN &&
      storeReleasePlanInformation.value?.verifyStatus !== VERIFY_STATUS.PASS &&
      storeReleasePlanInformation.value?.releaseType === PRODUCT_LAUNCH_TYPE.PAGE_BUILD
    );
  });
  const isReleaseOnlyProductPage = computed(() => {
    return (
      !!(
        storeReleasePlanInformation.value?.plans?.page?.pageOpenedAt &&
        formatUnknownSrcToMillis(storeReleasePlanInformation.value?.plans?.page?.pageOpenedAt) <
          formatUnknownSrcToMillis(new Date())
      ) &&
      storeReleasePlanInformation.value?.planStatus !== PLAN_STATUS.NONE &&
      storeReleasePlanInformation.value?.planStatus !== PLAN_STATUS.READY
    );
  });
  const isReleaseAtDateOverDateNow = computed(() => {
    if (!releasedAt.value) {
      return false;
    }
    return formatUnknownSrcToMillis(releasedAt.value) < Date.now();
  });
  const isPrePurchaseStartedAtDateOverDateNow = computed(() => {
    if (!prePurchaseStartedAt.value) {
      return false;
    }
    return formatUnknownSrcToMillis(prePurchaseStartedAt.value) < Date.now();
  });
  const isPrePurchaseEndedAtDateOverDateNow = computed(() => {
    if (!prePurchaseEndedAt.value) {
      return false;
    }
    return formatUnknownSrcToMillis(prePurchaseEndedAt.value) < Date.now();
  });
  const isEarlyAccessStartedAtDateOverDateNow = computed(() => {
    if (!earlyAccessStartedAt.value) {
      return false;
    }
    return formatUnknownSrcToMillis(earlyAccessStartedAt.value) < Date.now();
  });
  const isEarlyAccessEndedAtDateOverDateNow = computed(() => {
    if (!earlyAccessEndedAt.value) {
      return false;
    }
    return formatUnknownSrcToMillis(earlyAccessEndedAt.value) < Date.now();
  });
  const isStopSale = computed(
    () =>
      storeReleasePlanInformation.value?.saleStatus === SALE_STATUS.STOP ||
      storeReleasePlanInformation.value?.saleStatus === SALE_STATUS.END
  );
  const isNotDisplay = computed(
    () => storeReleasePlanInformation.value?.displayYn === Confirmation.NO
  );

  const getReleasePlanInformation = async (productNo: number) => {
    let dataInformation: ReleasePlanInformation | undefined | {} = {};
    try {
      dataInformation = await fetchReleasePlanInformationApi(productNo);
    } catch (err: any) {
      if (err?.statusCode !== STATUS_CODE.PRODUCT_RELEASE_NOT_FOUND) {
        // console.error(err);
      }
    }
    dataInformation = {
      verifyStatus: VERIFY_STATUS.NONE,
      planStatus: PLAN_STATUS.NONE,
      ...dataInformation
    } as ReleasePlanInformation;
    storeReleasePlanInformation.value = JSON.parse(JSON.stringify(dataInformation));
  };

  /**
   * Release status
   * **/

  const productReleaseStatus = ref<number>();
  // 2-1
  const isBeforeRequiredItemEnteredStatus = computed(() => {
    return productReleaseStatus.value === RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUIRED_ITEM_ENTERED;
  });
  // 2-2
  const isBeforeRequestingReviewStatus = computed(() => {
    return productReleaseStatus.value === RELEASE_PLAN_PRODUCT_STATUS.BEFORE_REQUESTING_REVIEW;
  });
  // 2-3
  const isRequestedReviewStatus = computed(() => {
    return productReleaseStatus.value === RELEASE_PLAN_PRODUCT_STATUS.REQUESTED_REVIEW;
  });
  // 2-4
  const isUnderReviewStatus = computed(() => {
    return productReleaseStatus.value === RELEASE_PLAN_PRODUCT_STATUS.UNDER_REVIEW;
  });
  // 2-5
  const isOperatorRejectedSanctionedStatus = computed(() => {
    return productReleaseStatus.value === RELEASE_PLAN_PRODUCT_STATUS.OPERATOR_REJECTED_SANCTIONED;
  });
  // 2-6 Review Completed
  const isReviewCompletedStatus = computed(() => {
    return productReleaseStatus.value === RELEASE_PLAN_PRODUCT_STATUS.REVIEW_COMPLETED;
  });
  // 2-7
  const isReleaseLiveStatus = computed(() => {
    return productReleaseStatus.value === RELEASE_PLAN_PRODUCT_STATUS.RELEASE_LIVE;
  });
  // 2-8
  const isReleaseStoppedStatus = computed(() => {
    return productReleaseStatus.value === RELEASE_PLAN_PRODUCT_STATUS.RELEASE_STOPPED;
  });
  //
  const isReleaseRestrictStatus = computed(() => {
    return productReleaseStatus.value === RELEASE_PLAN_PRODUCT_STATUS.RELEASE_RESTRICT;
  });

  /**
   * Check List Data
   * **/
  const checkListData = ref<ProductReleasePlanCheckList>({
    productSetting: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
    productPage: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
    storeCommunity: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
    earlyAccess: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
    storePageOpen: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN
  });
  const isErrorCheckList = computed(() => {
    return Object.values(checkListData.value).includes(RELEASE_PLAN_CHECK_LIST_STATUS.REJECT);
  });

  const initCheckListData = (isOnlyOpenProductPage: boolean) => {
    if (isOnlyOpenProductPage) {
      checkListData.value = {
        productSetting: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        productPage: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        storeCommunity: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        earlyAccess: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        storePageOpen: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN
      };
    } else {
      checkListData.value = {
        productSetting: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        productPage: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        storeCommunity: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        earlyAccess: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        executionSettings: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        uploadBuildFile: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        ageRatingAndBuild: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        inGameStore: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        productTerms: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        achievements: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        rankings: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        cloudSaving: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        matchMaking: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        priceSettings: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        purchaseConditions: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        storePageOpen: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        prePurchase: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN,
        officialRelease: RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN
      };
    }
    switch (currentProductDetailType.value) {
      case PRODUCT_TYPE_DETAIL.GAME_DEMO:
        checkListData.value.earlyAccess = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.priceSettings = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.prePurchase = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        break;
      case PRODUCT_TYPE_DETAIL.GAME_DLC:
        checkListData.value.inGameStore = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.productTerms = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.achievements = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.rankings = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.cloudSaving = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.matchMaking = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        break;
      case PRODUCT_TYPE_DETAIL.UTILITY_BASIC:
        checkListData.value.ageRatingAndBuild = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.inGameStore = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.matchMaking = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        break;
      case PRODUCT_TYPE_DETAIL.UTILITY_DEMO:
        checkListData.value.earlyAccess = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.ageRatingAndBuild = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.inGameStore = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.matchMaking = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.priceSettings = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        break;
      case PRODUCT_TYPE_DETAIL.UTILITY_DLC:
        checkListData.value.ageRatingAndBuild = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.inGameStore = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.productTerms = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.achievements = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.rankings = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.cloudSaving = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.matchMaking = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        break;
      case PRODUCT_TYPE_DETAIL.CONTENT_BASIC:
        checkListData.value.earlyAccess = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.ageRatingAndBuild = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.productTerms = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        break;
      case PRODUCT_TYPE_DETAIL.CONTENT_DEMO:
        checkListData.value.earlyAccess = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.ageRatingAndBuild = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.productTerms = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.priceSettings = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        checkListData.value.prePurchase = RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
        break;
      default:
        break;
    }
  };

  const isCheckListWithoutBuildCompleted = computed(() => {
    return (
      !isErrorCheckList.value &&
      checkListData.value.productSetting === RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED &&
      checkListData.value.productPage === RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED &&
      checkListData.value.storePageOpen === RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED
    );
  });
  const isCheckListWithBuildCompleted = computed(() => {
    return (
      !isErrorCheckList.value &&
      isCheckCompleted(checkListData.value?.productSetting) &&
      isCheckCompleted(checkListData.value?.productPage) &&
      isCheckCompleted(checkListData.value?.storePageOpen) &&
      isCheckCompleted(checkListData.value?.executionSettings) &&
      isCheckCompleted(checkListData.value?.uploadBuildFile) &&
      isCheckCompleted(checkListData.value?.ageRatingAndBuild) &&
      isCheckCompleted(checkListData.value?.priceSettings) &&
      isCheckCompleted(checkListData.value?.officialRelease)
    );
  });
  const isCheckListHidden = (data: CheckListStatus | undefined) => {
    return data === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN;
  };
  const isCheckCompleted = (item: CheckListStatus | undefined) => {
    if (!item) {
      return false;
    }
    return (
      item === RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED ||
      item === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN
    );
  };

  // Release product setting
  const setProductSettingCheckList = (isCompleted: boolean, isDataReject?: boolean) => {
    if (checkListData.value.productSetting === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isDataReject) {
      checkListData.value.productSetting = RELEASE_PLAN_CHECK_LIST_STATUS.REJECT;
      return;
    }
    if (isCompleted) {
      checkListData.value.productSetting = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.productSetting = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setProductPageCheckList = (isCompleted: boolean, isDataReject?: boolean) => {
    if (checkListData.value.productPage === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isDataReject) {
      checkListData.value.productPage = RELEASE_PLAN_CHECK_LIST_STATUS.REJECT;
      return;
    }
    if (isCompleted) {
      checkListData.value.productPage = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.productPage = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };

  // Release product page option
  const earlyAccessCheckListTextError = ref<string | null>('');
  const setStoreCommunityCheckList = (isCompleted: boolean) => {
    if (checkListData.value.storeCommunity === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.storeCommunity = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.storeCommunity = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setEarlyAccessCheckListCompleted = (isCompleted: boolean = true) => {
    if (checkListData.value.earlyAccess === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.earlyAccess = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.earlyAccess = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setEarlyAccessCheckListError = () => {
    if (checkListData.value.earlyAccess === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    checkListData.value.earlyAccess = RELEASE_PLAN_CHECK_LIST_STATUS.REJECT;
  };

  // Release preparing to build
  const setExecutionSettingsCheckList = (isCompleted: boolean) => {
    if (checkListData.value.executionSettings === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.executionSettings = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.executionSettings = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setUploadingBuildFileCheckList = (isCompleted: boolean) => {
    if (checkListData.value.uploadBuildFile === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.uploadBuildFile = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.uploadBuildFile = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setAgeRatingAndBuildCheckList = (isCompleted: boolean, isDataReject?: boolean) => {
    if (checkListData.value.ageRatingAndBuild === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isDataReject) {
      checkListData.value.ageRatingAndBuild = RELEASE_PLAN_CHECK_LIST_STATUS.REJECT;
      return;
    }
    if (isCompleted) {
      checkListData.value.ageRatingAndBuild = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.ageRatingAndBuild = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };

  const setAgeRatingAndBuildCheckListError = () => {
    if (checkListData.value.ageRatingAndBuild === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    checkListData.value.ageRatingAndBuild = RELEASE_PLAN_CHECK_LIST_STATUS.REJECT;
  };

  // Release setting up game support services
  const setInGameStoreCheckList = (isCompleted: boolean) => {
    if (checkListData.value.inGameStore === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.inGameStore = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.inGameStore = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setProductTermsCheckList = (isCompleted: boolean) => {
    if (checkListData.value.productTerms === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.productTerms = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.productTerms = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setAchievementsCheckList = (isCompleted: boolean) => {
    if (checkListData.value.achievements === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.achievements = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.achievements = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setRankingsCheckList = (isCompleted: boolean) => {
    if (checkListData.value.rankings === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.rankings = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.rankings = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setCloudSavingCheckList = (isCompleted: boolean) => {
    if (checkListData.value.cloudSaving === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.cloudSaving = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.cloudSaving = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setMatchMakingCheckList = (isCompleted: boolean) => {
    if (checkListData.value.matchMaking === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.matchMaking = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.matchMaking = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };

  // Release sales setting
  const priceSettingsCheckListTextError = ref<string | null>('');
  const setPriceSettingsCheckListCompleted = (isCompleted: boolean, isDataReject?: boolean) => {
    if (checkListData.value.priceSettings === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isDataReject) {
      checkListData.value.priceSettings = RELEASE_PLAN_CHECK_LIST_STATUS.REJECT;
      return;
    }
    if (isCompleted) {
      checkListData.value.priceSettings = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.priceSettings = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setPriceSettingsCheckListError = () => {
    if (checkListData.value.priceSettings === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    checkListData.value.priceSettings = RELEASE_PLAN_CHECK_LIST_STATUS.REJECT;
  };
  const setPurchaseConditionsCheckList = (isCompleted: boolean) => {
    if (checkListData.value.purchaseConditions === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.purchaseConditions = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.purchaseConditions = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };

  // Release launch plan
  const setStorePageOpenCheckList = (isCompleted: boolean) => {
    if (checkListData.value.storePageOpen === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.storePageOpen = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.storePageOpen = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setStorePageOpenCheckListError = () => {
    if (checkListData.value.storePageOpen === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    checkListData.value.storePageOpen = RELEASE_PLAN_CHECK_LIST_STATUS.REJECT;
  };
  const setPrePurchaseCheckListCompleted = (isCompleted: boolean) => {
    if (checkListData.value.prePurchase === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.prePurchase = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.prePurchase = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setPrePurchaseCheckListError = () => {
    if (checkListData.value.prePurchase === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    checkListData.value.prePurchase = RELEASE_PLAN_CHECK_LIST_STATUS.REJECT;
  };
  const setOfficialReleaseCheckListCompleted = (isCompleted: boolean) => {
    if (checkListData.value.officialRelease === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    if (isCompleted) {
      checkListData.value.officialRelease = RELEASE_PLAN_CHECK_LIST_STATUS.COMPLETED;
      return;
    }
    checkListData.value.officialRelease = RELEASE_PLAN_CHECK_LIST_STATUS.NOT_WRITTEN;
  };
  const setOfficialReleaseCheckListError = () => {
    if (checkListData.value.officialRelease === RELEASE_PLAN_CHECK_LIST_STATUS.HIDDEN) {
      return;
    }
    checkListData.value.officialRelease = RELEASE_PLAN_CHECK_LIST_STATUS.REJECT;
  };

  return {
    storeReleasePlanDetail,
    storeReleasePlanInformation,
    isEndDateOverDateNow,
    isReleaseAtDateOverDateNow,
    isPrePurchaseStartedAtDateOverDateNow,
    isPrePurchaseEndedAtDateOverDateNow,
    isEarlyAccessStartedAtDateOverDateNow,
    isEarlyAccessEndedAtDateOverDateNow,
    isOnlyPageOpenReleased,
    isOfficialReleased,
    isOnlyPageOpenToOfficialReleased,
    isReleaseOnlyProductPage,
    isStopSale,
    isNotDisplay,
    getReleasePlanInformation,
    // Status
    productReleaseStatus,
    isBeforeRequiredItemEnteredStatus,
    isBeforeRequestingReviewStatus,
    isRequestedReviewStatus,
    isUnderReviewStatus,
    isOperatorRejectedSanctionedStatus,
    isReviewCompletedStatus,
    isReleaseLiveStatus,
    isReleaseStoppedStatus,
    isReleaseRestrictStatus,
    // Check List
    checkListData,
    isCheckListWithoutBuildCompleted,
    isCheckListWithBuildCompleted,
    isCheckListHidden,
    isCheckCompleted,
    initCheckListData,
    // Release product setting
    setProductSettingCheckList,
    setProductPageCheckList,
    // Release product page option
    setStoreCommunityCheckList,
    setEarlyAccessCheckListCompleted,
    setEarlyAccessCheckListError,
    earlyAccessCheckListTextError,
    // Release preparing to build
    setExecutionSettingsCheckList,
    setUploadingBuildFileCheckList,
    setAgeRatingAndBuildCheckList,
    setAgeRatingAndBuildCheckListError,
    // Release setting up game support services
    setInGameStoreCheckList,
    setProductTermsCheckList,
    setAchievementsCheckList,
    setRankingsCheckList,
    setCloudSavingCheckList,
    setMatchMakingCheckList,
    // Release sales setting
    priceSettingsCheckListTextError,
    setPriceSettingsCheckListCompleted,
    setPriceSettingsCheckListError,
    setPurchaseConditionsCheckList,
    // Release launch plan
    setStorePageOpenCheckList,
    setStorePageOpenCheckListError,
    setPrePurchaseCheckListCompleted,
    setPrePurchaseCheckListError,
    setOfficialReleaseCheckListCompleted,
    setOfficialReleaseCheckListError
  };
});
